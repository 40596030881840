import { NgForOf, NgIf, NgStyle } from '@angular/common';
import { Component, forwardRef, Input, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IconComponent } from '@app/+authenticated/shared/icon.component';
import { TranslateModule } from '@ngx-translate/core';
import { ColorPickerComponent } from '@sb/ui';
import { LightenHexPipe } from '@shared/helpers/lighten-hex.pipe';

import { ModalFieldComponent } from '../forms/modal-field.component';
import { isColorDark } from './contrast.helper';

export const COLOR_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ColorInputComponent),
  multi: true,
};

@Component({
  selector: 'color-input',
  styles: ['color-input .p-colorpicker-panel { top: 2rem !important;  left: -0.375rem !important; }'],
  encapsulation: ViewEncapsulation.None,
  template: `
    <div class="flex flex-col items-start">
      <div class="grid grid-flow-col grid-rows-2">
        <ng-container *ngFor="let hex of colors">
          <span>
            <div
              class="swatch flex items-center justify-center"
              (click)="onChangeColorPicker(hex)"
              [ngStyle]="{
                backgroundColor: hex,
                outline: '3px solid',
                outlineColor: this.color === hex ? (hex | lightenHex: { forceLuminosity: 80 }) : 'transparent'
              }"
              [title]="hex"
            >
              <icon *ngIf="this.color === hex" class="si text-white" svgIcon="Approved"></icon>
            </div>
          </span>
        </ng-container>
      </div>
      <div class="flex flex-row">
        <modal-field [showLabel]="false" class="w-full" [overrideContentClass]="'flex flex-row items-center mx-4'">
          <span class="font-semibold">{{ 'Custom color' | translate }}</span>
          <div class="form-modal__input rounded items-center flex !flex-row px-1 py-0 ml-1.5 w-28">
            <sb-color-picker
              #sbColorPicker
              prefix
              [color]="color"
              [format]="'hex'"
              (colorPicked)="onChangeInput($event)"
            ></sb-color-picker>
            <input
              class="color-input border-0"
              type="text"
              [value]="color"
              data-lpignore="true"
              [ngModel]="color"
              (ngModelChange)="onChangeInput($event)"
              [ngModelOptions]="{ standalone: true }"
              [disabled]="disabled"
              (blur)="onTouched()"
            />
          </div>
        </modal-field>
      </div>
    </div>
  `,
  providers: [COLOR_INPUT_CONTROL_VALUE_ACCESSOR],
  standalone: true,
  imports: [
    NgStyle,
    IconComponent,
    TranslateModule,
    ColorPickerComponent,
    FormsModule,
    NgIf,
    NgForOf,
    LightenHexPipe,
    ModalFieldComponent,
  ],
})
export class ColorInputComponent implements ControlValueAccessor {
  @Input()
  public colors: string[];

  public color: string;

  public disabled: boolean;
  public color_is_dark = true;

  public onTouched: () => void;
  public onModelChange: (val: string) => void;

  public onChangeInput(value: string) {
    this.onChange(value);
  }

  public onChangeColorPicker(value: string) {
    this.onChange(value);
  }

  private onChange(value: string) {
    if (this.color === value) {
      return;
    }

    this.color_is_dark = isColorDark(value);

    this.color = value;

    this.onModelChange(value);
  }

  public registerOnChange(fn: (val: string) => void) {
    this.onModelChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public writeValue(value: string) {
    this.color = value;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
