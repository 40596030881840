import { Pipe, PipeTransform } from '@angular/core';
import { ColorType, lightenHex } from '@shiftbase-com/utilities';

interface LightenOptions {
  colorType?: ColorType;
  forceLuminosity?: number;
}
@Pipe({
  name: 'lightenHex',
  pure: true,
  standalone: true,
})
export class LightenHexPipe implements PipeTransform {
  public transform(color: string, options?: LightenOptions): string {
    return lightenHex(color, options?.colorType, options?.forceLuminosity);
  }
}
